export default {
  youtube: "再生能源憑證時光廊",
  youtubeLink: "https://www.youtube.com/watch?v=KBEM3LDCNAk",
  title: "經濟部標準檢驗局 ‧ 綠能新世代",
  webTitle: "引領！綠能新世代",
  webYear: "2024",
  webImages: "/images/bg2.jpg",
  webText:
    "經濟部標準檢驗局這次以「引領!綠能新世代!」為主題，打造六大主題展區，呈現我國再生能源檢測、驗證及政策推行成果，展現引領各界跟隨的理念，民眾可透過本網站了解再生能源及憑證發展趨勢，成為綠能新世代的一份子!",
  webBtn: "前往網站",
  webUrl: "2024",
  slideTitle: "歷年能源週網站",
}
