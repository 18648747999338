export default {
  youtube: "Time Gallery of T-REC",
  youtubeLink: "https://www.youtube.com/watch?v=KBEM3LDCNAk",
  title: "Green New Era Series Websites",
  webTitle: "Green New Era",
  webYear: "2024",
  webImages: "/images/bg2.jpg",
  webText:
    'The Bureau of Standards, Metrology and Inspection (BSMI) of the Ministry of Economic Affairs has themed this exhibition "Leading the New Generation of Green Energy!" and created six major thematic exhibition areas. These areas showcase Taiwan\'s achievements in renewable energy testing, verification, and policy implementation, embodying the concept of leading all circles to follow. Through this website, the public can gain insight into trends in renewable energy and certification, and become part of the new generation of green energy!',
  webBtn: "Enter",
  // webUrl: 'https://virtual-exhibition.kingone-design.com:8048/2022',
  webUrl: "2024",
  slideTitle: "Previous Websites",
}
