import { createStore } from 'vuex'
import { i18n } from '../data/i18n'
export default createStore({
  state: {
    //語言
    i18n: i18n,
    //預設語言
    lang: 'tw',
  },
  getters: {
    i18n: (state) => {
      let data = state.i18n[state.lang]
        ? state.i18n[state.lang]
        : state.i18n['tw']
      return data
    },
  },
  mutations: {
    setState(state, data) {
      // console.log("store room 3d", state, " fix  ", data);
      state[data.state] = data.value
    },
  },
  actions: {},
  modules: {},
})
