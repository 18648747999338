const sliderData = [
  {
    link: "2021",
    tw: {
      id: 0,
      imageUrl: "/images/slider/0.png",
      year: "2021",
      title: "來吧!綠能新世代",
      webBtn: "前往網站",
      text: "經濟部標準檢驗局是檢測與驗證的國家主管機關，以「2021來吧！綠能新世代！」主題，打造五大主題區，呈現我國再生能源檢測及驗證推動成果。民眾能夠透過本網站了解標準檢驗局在綠能推動、檢測驗證上的成績。",
    },
    en: {
      id: 0,
      imageUrl: "/images/slider/0.png",
      webBtn: "Enter",
      year: "2021",
      title: "Green New Era",
      text: 'The Bureau of Standards and Inspection of the Ministry of Economic Affairs is the national competent authority for testing and verification. With the theme of "Green New Era 2021!"The public can learn about the achievements of the Bureau of Standards and Inspection in green energy promotion, testing and verification through this website.',
    },
  },
  {
    link: "2022",
    tw: {
      id: 1,
      imageUrl: "/images/bg1.png",
      year: "2022",
      title: "驅動!綠能新世代",
      webBtn: "前往網站",
      text: "今年的「2022驅動！綠能新世代！」主題，邀請民眾一起攜手驅動臺灣再生能源及憑證發展。五大主題區的展示內容在於提供科普性的知識，還有互動小遊戲考驗民眾對再生能源憑證制度的了解。",
    },
    en: {
      id: 1,
      imageUrl: "/images/bg1.png",
      year: "2022",
      title: "Green New Era",
      webBtn: "Enter",
      text: "This year's theme of \"Green New Era 2022 !\" invites the public to join hands to promote the development of renewable energy and certificates in Taiwan. The display content of the five theme areas is to provide popular knowledge, and there are interactive mini games to test the public's understanding of the renewable energy certificate system.",
    },
  },
  {
    link: "2023",
    tw: {
      id: 2,
      imageUrl: "/images/bg1.png",
      year: "2023",
      title: "擁抱！綠能新世代",
      webBtn: "前往網站",
      text: "從來吧！到驅動！標準檢驗局這次以「擁抱！綠能新世代！」為主題，邀約民眾瀏覽精彩的五大主題展區，了解再生能源及憑證發展趨勢，擁抱綠能新知，共同邁向淨零未來！",
    },
    en: {
      id: 2,
      imageUrl: "/images/bg1.png",
      year: "2023",
      title: "Green New Era",
      webBtn: "Enter",
      text: "This year's theme of \"Green New Era 2023 !\" invites the public to join hands to promote the development of renewable energy and certificates in Taiwan. The display content of the five theme areas is to provide popular knowledge to help the public's understanding of the renewable energy certificate system.",
    },
  },
  {
    link: "2024",
    tw: {
      id: 2,
      imageUrl: "/images/bg2.jpg",
      year: "2024",
      title: "引領！綠能新世代",
      webBtn: "前往網站",
      text: "經濟部標準檢驗局這次以「引領!綠能新世代!」為主題，打造六大主題展區，呈現我國再生能源檢測、驗證及政策推行成果，展現引領各界跟隨的理念，民眾可透過本網站了解再生能源及憑證發展趨勢，成為綠能新世代的一份子!",
    },
    en: {
      id: 2,
      imageUrl: "/images/bg2.jpg",
      year: "2024",
      title: "Green New Era",
      webBtn: "Enter",
      text: 'The Bureau of Standards, Metrology and Inspection (BSMI) of the Ministry of Economic Affairs has themed this exhibition "Leading the New Generation of Green Energy!" and created six major thematic exhibition areas. These areas showcase Taiwan\'s achievements in renewable energy testing, verification, and policy implementation, embodying the concept of leading all circles to follow. Through this website, the public can gain insight into trends in renewable energy and certification, and become part of the new generation of green energy!',
    },
  },
]
export { sliderData }
